<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

      <InputGenericRadioOption
        class="pl-5"
        id="input-theme-scheme"
        :model="$v.selectedOption"
        :options="options"
        :submissionStates="submissionStates"
        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        :displayLabel="false"
      >
      </InputGenericRadioOption>

      <div
        v-if="selectedOption == 2"
        class="row d-flex justify-content-start mx-10"
      >
        <template v-for="(swatch, i) in swatches">
          <ThemeSwatch
            :key="i"
            :swatch="swatch"
            :selected="isSelected(swatch)"
            @selected="selectedSwatch = $event"
          >
          </ThemeSwatch>
        </template>
      </div>

      <div
        v-if="selectedOption == 3"
        class="row mx-0 justify-content-start w-100 d-flex"
      >
        <div class="col-6">
          <p class="mb-2 text-center" style="max-width:300px">{{ $t('BRAND.OPTIONS.PRIMARY') }}</p>
          <v-color-picker v-model="color1" :mode.sync="colorModes.mode" hide-mode-switch flat hide-sliders />
        </div>
        <div class="col-6">
          <p class="mb-2 text-center" style="max-width:300px">{{ $t('BRAND.OPTIONS.PRIMARY_TEXT') }}</p>
          <v-color-picker v-model="color2" :mode.sync="colorModes.mode" hide-mode-switch flat hide-sliders />
        </div>
        <div class="col-6">
          <p class="mb-2 text-center" style="max-width:300px">{{ $t('BRAND.OPTIONS.SECONDARY') }}</p>
          <v-color-picker v-model="color3" :mode.sync="colorModes.mode" hide-mode-switch flat hide-sliders />
        </div>
        <div class="col-6">
          <p class="mb-2 text-center" style="max-width:300px">{{ $t('BRAND.OPTIONS.SECONDARY_TEXT') }}</p>
          <v-color-picker v-model="color4" :mode.sync="colorModes.mode" hide-mode-switch flat hide-sliders />
        </div>
      </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRadioOption from "@/view/components/form-inputs/GenericRadioOption";
import ThemeSwatch from "@/view/components/ThemeSwatch";

export default {
  mixins: [validationMixin],

  name: "ThemeOptionSpecialScheme",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      options: [
        { text: `${this.$t('BRAND.OPTIONS.INHERIT')}`, value: 1 },
        { text: `${this.$t('BRAND.OPTIONS.PRE_SELECTED')}`, value: 2 },
        { text: `${this.$t('BRAND.OPTIONS.CUSTOM')}`, value: 3 }
      ],
      selectedOption: 1,
      selectedSwatch: [],
      colorModes: {
        mode: 'hexa',
        modes: ['hexa'],
      },
      swatches: [
        ["#F13C20","#EFE2BA","#4056A1","#C5CBE3"],
        ["#116466","#D1E8E2","#2C3531","#FFCB9A"],
        ["#5DA2D5","#ECECEC","#F78888","#ECECEC"],
        ["#000000","#Fba92c","#Fba92c","#000000"],
        ["#666666","#ffffff","#c5584a","#ffffff"],
        ["#5178aa","#ffffff","#25db8f","#ffffff"],
        ["#5178aa","#ffffff","#ecea74","#000000"],
        ["#b45d90","#ffffff","#703a8d","#ffffff"],
      ]
    }
  },

  validations: {
    selectedOption: {}
  },

  components: {
    FormSectionTitle,
    InputGenericRadioOption,
    ThemeSwatch
  },

  methods: {
    isSelected: function(activeSwatch) {
      let a = JSON.stringify(activeSwatch);
      let b = JSON.stringify(this.selectedSwatch);
      let c = a.indexOf(b);

      return (c != -1) ? true : false;
    },
    isPremadeSwatch: function(swatch) {
      var a = this.swatches;
      var b = swatch;

      a = JSON.stringify(a);
      b = JSON.stringify(swatch);

      var c = a.indexOf(b);

      return (c != -1) ? true : false;
    }
  },

  watch: {
    selectedOption: {
      handler(newVal) {
        this.form[this.option].value = newVal;
        if (newVal == 2 && !this.isPremadeSwatch(this.form[this.option].options)) {
          this.selectedSwatch = this.swatches[0];
        }
      }
    },
    selectedSwatch: {
      handler(newVal) {
        this.form[this.option].options = newVal;
      }
    },
    entry: {
      handler(newVal) {
        this.selectedOption = newVal.value;
        this.selectedSwatch = newVal.options;
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      return this.form[this.option];
    },
    color1: {
      get() {
        return this.form[this.option].options[0];
      },
      set(newVal) {
        this.form[this.option].options[0] = newVal;
      }
    },
    color2: {
      get() {
        return this.form[this.option].options[1];
      },
      set(newVal) {
        this.form[this.option].options[1] = newVal;
      }
    },
    color3: {
      get() {
        return this.form[this.option].options[2];
      },
      set(newVal) {
        this.form[this.option].options[2] = newVal;
      }
    },
    color4: {
      get() {
        return this.form[this.option].options[3];
      },
      set(newVal) {
        this.form[this.option].options[3] = newVal;
      }
    },
    filled: function() {
      return (this.selectedOption.value != 1);
    }
  }
}
</script>

<style>
  .v-color-picker__edit {
    margin-top: 0px !important;
  }
</style>