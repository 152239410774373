<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <div class="row col-xl-12 d-flex justify-content-start mb-10 px-3 mx-0">
      <InputGenericRichText
        id="input-email-footer"
        :model="$v.rich"
        :submissionStates="submissionStates"
      >
      </InputGenericRichText>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRichText from "@/view/components/form-inputs/GenericRichText";

export default {
  mixins: [validationMixin],

  name: "ThemeOptionRich",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      rich: ""
    }
  },

  components: {
    FormSectionTitle,
    InputGenericRichText
  },

  validations: {
    rich: { }
  },

  watch: {
    entry: {
      handler(newVal) {
        this.rich = newVal.value;
      },
      immediate: true
    },
    rich: {
      handler(newVal) {
        this.form[this.option].value = newVal;
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      this.submissionStates.submitting;
      return this.form[this.option];
    },
    filled: function() {
      return (this.rich != null && this.rich.length > 0) ? true : false;
    }
  }
}
</script>