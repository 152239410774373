<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <InputGenericRadioOption
      class="pl-5 mb-10"
      :id="`toggle-url-${option}`"
      :model="$v.selectedOption"
      :options="options"
      :submissionStates="submissionStates"
      :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
      :displayLabel="false"
      :slim="true"
    >
    </InputGenericRadioOption>

    <InputGeneric
      v-if="selectedOption == 1"
      :id="`toggle-url-${option}-link`"
      :model="$v.link"
      alignLabel="left"
      :placeholder="$t('FORMS.INPUTS.URL')"
      :submissionStates="submissionStates"
      :showInline="true"
      error="This field must be a valid URL"
      type="text"
    >
    </InputGeneric>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { url, requiredIf } from "vuelidate/lib/validators";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";
import InputGeneric from "@/view/components/form-inputs/GenericInput";

export default {
  mixins: [validationMixin],

  name: "ToggleUrl",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      options: [
        { text: `${this.$t('BRAND.OPTIONS.NO_LINK')}`, value: null},
        { text: `${this.$t('BRAND.OPTIONS.LINK')}`, value: 1 }
      ],
      selectedOption: 1,
      link: ""
    }
  },

  components: {
    FormSectionTitle,
    InputGenericRadioOption,
    InputGeneric
  },

  validations: {
    selectedOption: {},
    link: {
      url,
      required: requiredIf(function() {
        return this.selectedOption == 1;
      })
    }
  },

  watch: {
    selectedOption: {
      handler(newVal) {
        this.form[this.option].value = newVal;
      }
    },
    link: {
      handler(newVal) {
        this.form[this.option].options.link = newVal;
      }
    },
    entry: {
      handler(newVal) {
        this.selectedOption = newVal.value;
        this.link = newVal.options.link;
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      return this.form[this.option];
    },
    filled: function() {
      return (this.selectedOption == 1 && this.$v.link.$anyError == false)
    }
  }
}
</script>