<template>
  <div class="col-3 p-0 border-right">
    <ul class="nav flex-column mt-5">

      <template v-for="(rootTab, rt) in systemProjectSettingCategories">
        <div :key="rt">
          <a
            class="nav-link px-2"
            data-toggle="tab"
            href="javascript:void(0)"
            @click="$emit('on-tab-change',rootTab)"
            :class="{ 'active' : selected_tab == rootTab.id }"
          >
            <span class="nav-text d-flex justify-content-start">
              <div
                class="selection-indicator"
                :class="{ 'bg-primary' : selected_tab == rootTab.id }"
              />
              {{ $t(`SYSTEM.PROJECT_SETTING_CATEGORY.${rootTab.id}`)}}
            </span>
          </a>
          <div class="ml-7">
            <template v-for="(childTab, ct) in rootTab.children">
              <a
                :key="ct"
                class="nav-link px-2"
                data-toggle="tab"
                href="javascript:void(0)"
                @click="$emit('on-tab-change',childTab)"
                :class="{ 'active' : selected_tab == childTab.id }"
              >
                <span class="nav-text d-flex justify-content-start">
                  <div
                    class="selection-indicator"
                    :class="{ 'bg-primary' : selected_tab == childTab.id }"
                  />
                  {{ $t(`SYSTEM.PROJECT_SETTING_CATEGORY.${childTab.id}`)}}
                </span>
              </a>
            </template>
          </div>
        </div>
      </template>

    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ThemeNavigator",

  props: {
    selected_tab: { type: Number, required: true }
  },

  computed: {
    ...mapGetters([
      "projectBrandTabs",
      "projectBrandList",
      "systemProjectSettingCategories"
    ])
  }
};
</script>

<style scoped>
  .selection-indicator {
    width: 3px;
    margin-right: 7px;
  }
</style>
