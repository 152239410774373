<template>
  <div>
    <h3
      class="font-size-lg text-dark font-weight-bold mt-10 px-2"
      :class="{ 'mb-3' : hasDescription, 'mb-6' : !hasDescription }"
    >
      {{ title }}:
    </h3>
    <p
      v-if="hasDescription"
      class="px-2"
    >
      {{ description }}
    </p>
    <p
      v-if="moaSpecific && !filled"
      class="text-danger px-2"
    >
      {{ $t('BRAND.DESCRIPTORS.MOA_SPECIFIC') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "FormSectionTitle",

  props: {
    title: { type: String, required: true },
    description: { type: String, required: false },
    moaSpecific: { type: Boolean, required: false, default: false },
    filled: { type: Boolean, required: true }
  },

  computed: {
    hasDescription: function() {
      if (this.description != undefined) {
        if (this.description != null && this.description.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
};
</script>
