<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <div class="row col-xl-12 d-flex justify-content-start mb-10 px-0 mx-0">
      <IntegratedDropzone
        :ref="`dropzone-ref-${option}`"
        class="col-xl-12 px-3"
        :assetReservation="assetReservationData"
        :idx="option"
        :projectAssets="entry.existing_asset"
        :wholeWidth="true"
        :removable="true"
        :initialPreviews=[]
        @asset_reservation_created="addImage($event)"
        @asset_reservation_deleted="deleteAssetReservation()"
        @remove_project_asset="entry.image_remove = $event"
      >
      </IntegratedDropzone>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

export default {
  name: "ThemeOptionAsset",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      uploads: 0
    }
  },

  components: {
    FormSectionTitle,
    IntegratedDropzone
  },

  methods: {
    deleteAssetReservation: function() {
      this.entry.image_add = null;

      this.$refs[`dropzone-ref-${this.option}`].clearPreviews();
      this.$refs[`dropzone-ref-${this.option}`].$refs[`arDropzone-${this.option}`].$refs[`ref-integrated-dropzone-${this.option}`].removeAllFiles();

      this.uploads--;
    },
    addImage(image) {
      this.entry.image_add = image;
      this.uploads++;
    }
  },

  watch: {
    existingAsset: {
      handler() {
        this.$refs[`dropzone-ref-${this.option}`].clearPreviews();
        this.$refs[`dropzone-ref-${this.option}`].$refs[`arDropzone-${this.option}`].$refs[`ref-integrated-dropzone-${this.option}`].removeAllFiles();
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    assetReservationData: function() {
      let data = {
        scope: "project",
        data: {
          directory: "theme_images",
          projectId: this.projectInfo.id
        }
      };
      return data;
    },
    entry: function() {
      this.submissionStates.submitting;
      return this.form[this.option];
    },
    existingAsset: function() {
      return this.entry.existing_asset;
    },
    filled: function() {
      this.uploads;
      return (this.entry.existing_asset.length > 0 || this.entry.image_add != null) ? true : false;
    }
  }
}
</script>