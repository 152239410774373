<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <InputGenericRadioOption
      class="pl-5 mb-10"
      :id="`special-welcome-message-${option}`"
      :model="$v.selectedOption"
      :options="options"
      :submissionStates="submissionStates"
      :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
      :displayLabel="false"
      :slim="true"
    >
    </InputGenericRadioOption>

    <InputGeneric
      v-if="selectedOption == 1"
      :id="`special-welcome-message-${option}-title`"
      :model="$v.title"
      alignLabel="left"
      :placeholder="$t('FORMS.INPUTS.PROJECT_WELCOME_TITLE')"
      :submissionStates="submissionStates"
      :showInline="true"
      :error="$t('FORMS.ERRORS.REQUIRED')"
      type="text"
      class="px-3 mb-10"
    >
    </InputGeneric>

    <div v-if="selectedOption == 1" class="px-3">
      <InputGenericRichText
        :id="`special-welcome-message-${option}-message`"
        :model="$v.rich"
        :placeholder="$t('FORMS.INPUTS.PROJECT_WELCOME_MESSAGE')"
        :submissionStates="submissionStates"
        :error="$t('FORMS.ERRORS.REQUIRED')"
      >
      </InputGenericRichText>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericRichText from "@/view/components/form-inputs/GenericRichText";

export default {
  mixins: [validationMixin],

  name: "SpecialWelcomeMessage",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      options: [
        { text: `${this.$t('BRAND.OPTIONS.NO_WELCOME')}`, value: null},
        { text: `${this.$t('BRAND.OPTIONS.YES_WELCOME')}`, value: 1 }
      ],
      selectedOption: 1,
      title: "",
      rich: ""
    }
  },

  components: {
    FormSectionTitle,
    InputGenericRadioOption,
    InputGeneric,
    InputGenericRichText
  },

  validations: {
    selectedOption: {},
    title: {
      required: requiredIf(function() {
        return this.selectedOption == 1;
      })
    },
    rich: {
      required: requiredIf(function() {
        return this.selectedOption == 1;
      })
    }
  },

  watch: {
    selectedOption: {
      handler(newVal) {
        this.form[this.option].value = newVal;
      }
    },
    title: {
      handler(newVal) {
        this.form[this.option].options.title = newVal;
      }
    },
    rich: {
      handler(newVal) {
        this.form[this.option].options.message = newVal;
      }
    },
    entry: {
      handler(newVal) {
        this.selectedOption = newVal.value;
        this.title = newVal.options.title;
        this.rich = newVal.options.message;
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      return this.form[this.option];
    },
    filled: function() {
      return (this.selectedOption == 1 && this.title.length > 0 && this.rich.length > 0)
    }
  }
}
</script>