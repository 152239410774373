<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <InputGenericRadioOption
      class="pl-5"
      id="input-theme-welcome-placement"
      :model="$v.selectedOption"
      :options="options"
      :submissionStates="submissionStates"
      :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
      :displayLabel="false"
    >
    </InputGenericRadioOption>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRadioOption from "@/view/components/form-inputs/GenericRadioOption";

export default {
  mixins: [validationMixin],

  name: "ThemeOptionSpecialWelcome",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  data() {
    return {
      options: [
        { text: `${this.$t('BRAND.OPTIONS.DISPLAY_WELCOME')}`, value: 1 },
        { text: `${this.$t('BRAND.OPTIONS.HIDE_WELCOME')}`, value: 2 }
      ],
      selectedOption: 1
    }
  },

  components: {
    FormSectionTitle,
    InputGenericRadioOption
  },

  validations: {
    selectedOption: {}
  },

  watch: {
    selectedOption: {
      handler(newVal) {
        this.form[this.option].value = newVal
      }
    },
    entry: {
      handler(newVal) {
        this.selectedOption = newVal.value;
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      return this.form[this.option];
    },
    filled: function() {
      return true;
    }
  }
}
</script>