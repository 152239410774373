<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

      <InputGenericRadioOption
        class="pl-5"
        id="input-theme-overlay"
        :model="$v.selectedOption"
        :options="options"
        :submissionStates="submissionStates"
        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        :displayLabel="false"
        :slim="true"
      >
      </InputGenericRadioOption>

    <div
      class="row mx-0 justify-content-start w-100 d-flex"
      :class="{ 'mt-0' : [1,4,5].includes(parseInt(selectedOption)), 'mt-5' : selectedOption == 3, 'mt-13' : selectedOption == 2 }"
    >
      <div
        v-show="[2,3].includes(parseInt(selectedOption))"
        class="col-6"
      >
        <p
          v-if="selectedOption == 3"
          class="mb-2 text-center"
          style="max-width:300px"
        >
          {{ $t('BRAND.OPTIONS.TOP') }}
        </p>
        <v-color-picker
          v-model="color1"
          :mode.sync="colorModes.mode"
          hide-mode-switch flat hide-sliders
        />
      </div>
      <div
        v-show="selectedOption == 3"
        class="col-6"
      >
        <p
          class="mb-2 text-center"
          style="max-width:300px"
        >
          {{ $t('BRAND.OPTIONS.BOTTOM') }}
        </p>
        <v-color-picker
          v-model="color2"
          :mode.sync="colorModes.mode"
          hide-mode-switch flat hide-sliders
        />
      </div> 
    </div>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";

export default {
  mixins: [validationMixin],

  name: "ThemeOptionSpecialOverlay",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  components: {
    FormSectionTitle,
    InputGenericRadioOption
  },

  data() {
    return {
      options: [
        { text: `${this.$t('BRAND.OPTIONS.NO_OVERLAY')}`, value: 1 },
        { text: `${this.$t('BRAND.OPTIONS.SINGLE_OVERLAY')}`, value: 2 },
        { text: `${this.$t('BRAND.OPTIONS.DUAL_OVERLAY')}`, value: 3 },
        { text: `${this.$t('BRAND.OPTIONS.INHERIT_OVERLAY')}`, value: 4 },
        { text: `${this.$t('BRAND.OPTIONS.DARK_OVERLAY')}`, value: 5 }
      ],
      selectedOption: 1,
      colorModes: {
        mode: 'hexa',
        modes: ['hexa'],
      }
    }
  },

  validations: {
    selectedOption: {}
  },

  watch: {
    selectedOption: {
      handler(newVal) {
        this.form[this.option].value = newVal
      }
    },
    entry: {
      handler(newVal) {
        this.selectedOption = newVal.value;
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      return this.form[this.option];
    },
    color1: {
      get() {
        return this.form[this.option].options[0];
      },
      set(newVal) {
        this.form[this.option].options[0] = newVal;
      }
    },
    color2: {
      get() {
        return this.form[this.option].options[1];
      },
      set(newVal) {
        this.form[this.option].options[1] = newVal;
      }
    },
    filled: function() {
      return (this.selectedOption.value != 1);
    }
  }
}
</script>

<style>
  .v-color-picker__edit {
    margin-top: 0px !important;
  }
</style>