<template>
  <div>
    <FormSectionTitle
      :title="$t(`BRAND.DESCRIPTORS.${data.title}`)"
      :description="data.description != null ? $t(`BRAND.DESCRIPTORS.${data.description}`) : null"
      :moaSpecific="data.moa_setting"
      :filled="filled"
    />

    <div class="row col-xl-12 d-flex justify-content-start mb-10 px-3 mx-0">
      <InputGeneric
        id="input-logo-link"
        :model="$v.entry.value"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.URL')"
        :submissionStates="submissionStates"
        :showInline="true"
        error="This field must either be empty, or a valid URL"
        type="text"
      >
      </InputGeneric>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { url } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";
import { mapState } from "vuex";

import FormSectionTitle from "@/view/components/FormSectionTitle";
import InputGeneric from "@/view/components/form-inputs/GenericInput";

export default {
  mixins: [validationMixin],

  name: "ThemeOptionUrl",

  props: {
    data: { type: Object, required: true },
    option: { type: Number, required: true },
    submissionStates: { type: Object, required: true }
  },

  components: {
    FormSectionTitle,
    InputGeneric
  },

  validations: {
    entry: {
      value: { url }
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    entry: function() {
      this.submissionStates.submitting;
      return this.form[this.option];
    },
    filled: function() {
      return (this.entry.value != null && this.$v.entry.value.$anyError == false)
    }
  }
}
</script>