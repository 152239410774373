<template>
  <div class="row d-flex justify-content-start mx-0">
    <div class="form-group pt-2">
      <label class="checkbox">
        <input type="checkbox" name="Checkboxes1" :checked="selected" @click="$emit('selected',swatch)" :disabled="selected" />
        <span></span>
      </label>
    </div>
    <div class="col-3 btn-toolbar mb-5" role="toolbar" aria-label="Toolbar with button groups">
        <div class="btn-group mr-2" role="group" aria-label="First group">
            <button type="button" class="btn" :style="getStyle(0)"></button>
            <button type="button" class="btn" :style="getStyle(1)"></button>
            <button type="button" class="btn" :style="getStyle(2)"></button>
            <button type="button" class="btn" :style="getStyle(3)"></button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThemeSwatch",

  props: {
    swatch: { type: Array, required: true },
    selected: { type: Boolean, required: true, default: false }
  },

  methods: {
    getStyle: function(selector) {
      return "height:30px; width:35px; background: " + this.swatch[selector];
    },
    emitSelection: function() {
      window.alert("test");
    }
  }
}
</script>