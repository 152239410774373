<template>
  <div class="row mx-0 px-0">
    <div class="col-xl-12 pl-2 pr-2">
      <div class="card card-custom gutter-b">
        <div v-if="loadingComplete" class="card-header border-right">
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('BRAND.DESCRIPTORS.TITLE') }}
            </h3>
          </div>
        </div>
        <div class="card-body pt-5">

          <!--start::Page Loader-->
          <b-spinner
            v-if="loadingComplete == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>
          <!--start::Page Loader-->

          <div v-if="loadingComplete" class="row">

            <!--start::Navigator-->
            <ThemeNavigator
              :selected_tab="tab"
              @on-tab-change="onTabChange($event)"
            />
            <!--end::Navigator-->

            <!--start::Tab Content-->
            <div class="col-6">
              <div class="tab-content px-10">

                <ErrorBox
                  :errors="projectBrandErrors"
                  @clear_errors="setProjectBrandErrors()"
                />

                <div
                  v-if="tabEntry.message != null"
                  class="alert alert-secondary mb-20 mt-10"
                  role="alert"
                >
                  {{ $t(tabEntry.message) }}
                </div>

                <template v-for="(projectBrand, t) in projectBrandList">
                  
                  <div
                    v-if="form[projectBrand.key] != undefined"
                    :key="t"
                  >
                    <div
                      v-show="['middle','last'].includes(positionWithinPage(projectBrand.key))"
                      class="col-xl-8 separator separator-dashed my-20"
                    />

                    <component
                      v-show="effectiveTabs.includes(projectBrand.suggested_tab)"
                      :is="`ThemeOption${projectBrand.config.component}`"
                      :data="projectBrand.config"
                      :option="parseInt(projectBrand.key)"
                      :submissionStates="submissionStates"
                      :class="{ 'mb-20' : positionWithinPage(projectBrand.key) == 'last' }"
                    />                    
                  </div>

                </template>

              </div>
            </div>
            <!--end::Tab Content-->

          </div>
        </div>
        <div v-if="loadingComplete" class="card-footer d-flex justify-content-end my-0 py-4">
          <a
            href="javascript:void(0)"
            class="btn btn-primary"
            @click="onSubmit()"
          >
            {{ $t('GENERAL.BUTTONS.SAVE') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import PageLoader from "@/core/classes/PageLoader.js";

import ErrorBox from "@/view/components/ErrorBox";
import ThemeNavigator from "./theme/Navigator";
import ThemeOptionUrl from "./theme/options/Url";
import ThemeOptionAsset from "./theme/options/Asset";
import ThemeOptionRich from "./theme/options/Rich";
import ThemeOptionSpecialLogo from "./theme/options/SpecialLogo";
import ThemeOptionSpecialOverlay from "./theme/options/SpecialOverlay";
import ThemeOptionSpecialScheme from "./theme/options/SpecialScheme";
import ThemeOptionSpecialWelcome from "./theme/options/SpecialWelcome";
import ThemeOptionSpecialLinkRichAlt from "./theme/options/SpecialLinkRichAlt";
import ThemeOptionSpecialWelcomeMessage from "./theme/options/SpecialWelcomeMessage";
import ThemeOptionToggleUrl from "./theme/options/ToggleUrl";

export default {
  name: "Theme",

  data() {
    return {
      pageLoader: new PageLoader(2),
      error: {
        active: false,
        message: ""
      },
      submissionStates: {
        submitting: false
      },
      tab: null,
      tabEntry: null
    }
  },

  components: {
    ErrorBox,
    ThemeNavigator,
    ThemeOptionUrl,
    ThemeOptionAsset,
    ThemeOptionRich,
    ThemeOptionSpecialLogo,
    ThemeOptionSpecialOverlay,
    ThemeOptionSpecialScheme,
    ThemeOptionSpecialWelcome,
    ThemeOptionSpecialLinkRichAlt,
    ThemeOptionSpecialWelcomeMessage,
    ThemeOptionToggleUrl
  },

  mounted() {
    this.fetchSettingCategories();
    this.fetchExistingProjectBrand();
  },

  methods: {
    fetchSettingCategories: function() {
      let payload = {
        route: `api/v1/system_project_setting_categories`,
        filters: {
          parent: 0
        }
      };

      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          let tabs = res._embedded.system_project_setting_categories;
          this.setProjectSettingCategories(res._embedded.system_project_setting_categories);
          this.setInitialTab(tabs);
          this.pageLoader.setCompleteComponent();
        });
    },
    fetchExistingProjectBrand: function() {
      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/project_brands`,
        params: {
          projectAsset: 'with'
        }
      };
      this.$store.dispatch(GENERIC_RESOURCE, payload)
      .then((res) => {
        let projectBrands = res._embedded.project_brands;
        this.setProjectBrandList(projectBrands);
        this.buildProjectBrand(projectBrands).then(() => {
          this.pageLoader.setCompleteComponent();
        })
        this.pageLoader.setCompleteComponent();
      })
    },
    onSubmit: function() {
      this.submissionStates.submitting = true;

      let payload = {
        projectId: this.projectInfo.id,
        data: {
          config: this.form
        }
      };

      this.updateProjectBrand(payload)
        .then((res) => {
          let projectBrands = res._embedded.project_brands;
          this.buildProjectBrand(projectBrands);
          this.$root.$bvToast.toast(`${this.$t("TOASTS.EDIT_THEME.MESSAGE")}`, {
            title: `${this.$t("TOASTS.EDIT_THEME.TITLE")}`,
            autoHideDelay: 5000,
            appendToast: false,
            variant: "success"
          });
          this.setProjectBrandErrors();
        })
        .catch((res) => {
          this.setProjectBrandErrors(res.response.data);
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.scrollTop();
        })
    },
    positionWithinPage: function(projectBrandKey) {
      let index = this.viewableOptions.findIndex(x => x.key === projectBrandKey);
      let position = null;

      if (index == 0) {
        position = "first";
      } else if (index == -1) {
        position = null;
      } else if ((index + 1) == this.viewableOptions.length) {
        position = "last";
      } else {
        position = "middle";
      }

      return position;
    },
    onTabChange: function(tab) {
      this.tabEntry = tab;
      this.tab = tab.id;
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    setInitialTab: function(tabs) {
      if (this.$route.params.tab == undefined) {
        this.tab = tabs[0].id;
        this.tabEntry = tabs[0];        
      } else {
        let passedTabParam = parseInt(this.$route.params.tab);
        let preSelectedTab = tabs.filter(function(tab) {
          return tab.id == passedTabParam;
        })[0];
        this.tab = passedTabParam;
        this.tabEntry = preSelectedTab;
      }
    },
    ...mapActions({
      buildProjectBrand: "buildProjectBrand",
      updateProjectBrand: "updateProjectBrand"
    }),
    ...mapMutations({
      setProjectBrandList: "setProjectBrandList",
      setProjectBrandErrors: "setProjectBrandErrors",
      setProjectSettingCategories: "setProjectSettingCategories"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectBrandList",
      "projectBrandErrors",
      "systemProjectSettingCategories"
    ]),
    ...mapState({
      form: state => state.projectBrands.project_brand_form
    }),
    loadingComplete() {
      return this.pageLoader.getStatus();
    },
    assetReservationData: function() {
      let data = {
        scope: "project",
        data: {
          directory: "theme_images",
          projectId: this.projectInfo.id
        }
      };
      return data;
    },
    viewableOptions: function() {
      let effectiveTabs = this.effectiveTabs;
      return this.projectBrandList.filter(function(projectBrand) {
        return effectiveTabs.includes(projectBrand.suggested_tab);
      })
    },
    effectiveTabs: function() {
      let selectedTab = this.tab;

      let systemEntry = this.systemProjectSettingCategories.filter(function(category) {
        return category.id == selectedTab;
      })[0];

      if (systemEntry == undefined) {
        return [selectedTab];
      } else {
        let childTabIds = systemEntry.children.map(item => item['id']);
        return [selectedTab].concat(childTabIds);
      }
    }
  }
}
</script>
